import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';

const AutosuggestComponent = ({id, name, field_class, onSuggestionSelected, onCompanyChange, clearValue}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (clearValue) {
      setValue('');
    }
  }, [clearValue]);

  // Function to fetch suggestions
  const getSuggestions = async (inputValue) => {
    try {
        let apiUrl = `https://insidearbitrage.com/cct-scripts/autosuggest.php?term=${inputValue}&timestamp=${Date.now()}`;
        if(id === "manager-search"){
          apiUrl = `https://insidearbitrage.com/cct-scripts/autosuggest.php?term=${inputValue}&type=managers&timestamp=${Date.now()}`
        }
        if(id === "homepage-search" || id === "header-search" || id === "header-mobile-search"){
          apiUrl = `https://insidearbitrage.com/cct-scripts/autosuggest.php?term=${inputValue}&type=both&timestamp=${Date.now()}`
        }
        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json();
          setSuggestions(data); // Assuming your API returns suggestions as an array
        } else {
          throw new Error('Failed to fetch suggestions');
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
  };

  // Autosuggest input functions
  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  /*const onSuggestionSelected = (event, { suggestion }) => {
    if(id === "homepage-search" || id === "header-search" || id === "header-mobile-search"){
      let redirect_url = `${window.location.origin}/?co=${suggestion.id}`;
      window.location.href = redirect_url;
    }
  };*/

  const handleSuggestionSelected = (event, { suggestion }) => {
    if(id === "homepage-search" || id === "header-search" || id === "header-mobile-search"){
      let redirect_url = `${window.location.origin}/?co=${suggestion.id}`;
      window.location.href = redirect_url;
    }

    if (onSuggestionSelected) {
      //onSuggestionSelected(suggestion);
      //setValue('');
      onSuggestionSelected(suggestion, () => {setValue('')})
    }

    if(onCompanyChange){
      onCompanyChange(suggestion.company_id);
    }
  }

  const getSuggestionValue = (suggestion) => {
    let return_value = suggestion.id;
    if(id === "manager-search"){
      return_value = suggestion.label;
    }
    return return_value; // Adjust this based on your suggestion object structure
  };

  const renderSuggestion = (suggestion) => {
    return <div>{suggestion.label}</div>; // Adjust this based on your suggestion object structure
  };

  let placeholder_value = "Company name or Symbol";
  if(id === "homepage-search"){
    placeholder_value = "Enter Company name or Symbol";
  }

  if(id === "notifications-search"){
    placeholder_value = "Enter Company name or Symbol to Add to Notifications";
  }

  if(id === "site-search"){
    placeholder_value = "";
  }

  if(id === "manager-search"){
    placeholder_value = "Enter Money Manager name to Add to Notifications";
  }

  const inputProps = {
    placeholder: placeholder_value,
    value,
    onChange: onChange,
    name: name,
  };

  if (field_class) {
    inputProps.className = field_class;
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      id={id}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export default AutosuggestComponent;