import { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import MyAccountMenu from "../components/myAccountMenu.component";
import { getCookieValue, removeCookie } from "../utils/general.utils";

class ChangePassword extends Component {
    constructor(){
        super();

        this.state = {
            page_name: "change-password",
            showBtn: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            showForm: true,
            passwordChanged: false,
            formData: {
                current_password: "",
                new_password: "",
                confirm_new_password: ""
            }
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault();

        const{current_password, new_password, confirm_new_password} = this.state.formData;

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        const username = getCookieValue('cct_username');
        const user_id = getCookieValue('cct_user_id');

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/change_password.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                username: username,
                user_id: user_id,
                current_password: current_password,
                new_password: new_password,
                confirm_new_password: confirm_new_password
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, password_change_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        showBtn: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: "",
                        showForm: true
                    });
                } else {
                    if(successMsg !== "" && password_change_status === "changed"){
                        removeCookie('cct_username');
                        removeCookie('cct_password');
                        removeCookie('cct_user_email');
                        removeCookie('cct_user_id');

                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            showForm: false,
                            passwordChanged: true
                        });                     
                    } else {
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            showForm: true
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleChange = (e) => {
        const { name, value, type } = e.target;
        const fieldValue = value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: fieldValue
            }
        }));
    }

    render(){
        document.title = "Change Password - Conference Call Transcripts";

        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        const{page_name, showBtn, displayError, errorMsg, displaySuccess, successMsg, showForm, passwordChanged} = this.state;
        const { handleChange, handleSubmit } = this;

        if(cct_username === "" && cct_password === "" && passwordChanged === false){
            const redirect_url = `${window.location.origin}/my-account`;
            window.location.href = redirect_url;
        } else {
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">

                <MyAccountMenu page_name={page_name} />

                <div className="row">
                <div className="col-lg-12">
                <h4 className="text-center">Change Password</h4>
                </div>
                </div>

                <div className="row mt-3">
                <div className="col-md-4 offset-md-4 col-12">
                
                <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>

                <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>

                <div className="mt-2" style={showForm ? {display: 'block'} : {display: 'none'}}>
                <form onSubmit={handleSubmit}>

                <div className="row mt-2">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="current_password"><b>Current Password</b></label>
                <input type="password" className="form-control" id="current_password" name="current_password" onChange={handleChange} />
                </div>
                </div>
                </div>
                
                <div className="row mt-2">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="new_password"><b>New Password</b></label>
                <input type="password" className="form-control" id="new_password" name="new_password" onChange={handleChange} />
                <small id="new_passwordHelp" className="form-text text-muted">The password should be a minimum of 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.</small>
                </div>
                </div>
                </div>

                <div className="row mt-2">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="confirm_new_password"><b>Confirm New Password</b></label>
                <input type="password" className="form-control" id="confirm_new_password" name="confirm_new_password" onChange={handleChange} />
                </div>
                </div>
                </div>

                <div className="form-group mt-3">
                <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
                <div className="d-flex" >
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>

                <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Submit</button>
                </div>

                </form>
                </div>
                
                </div>
                </div>

                </div>
                </div>
            )
        }
    }
}

export default ChangePassword;