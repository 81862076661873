import { Component } from "react";
import MyAccountMenu from "../components/myAccountMenu.component";
import AutosuggestComponent from "../components/autosuggest.component";
import ReactHtmlParser from 'react-html-parser';
import { getCookieValue } from "../utils/general.utils";
import { formatDate } from "../utils/general.utils";
import AjaxLoaderImg from '../assets/img/ajax-loader-small.gif';


class MoneyManagers extends Component {
    constructor(){
        super();

        this.state = {
            page_name: "managers",
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            addedManagers: [],
            pageLoaded: false,
            manager_to_delete: "",
            adding_manager: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const cct_user_id = getCookieValue('cct_user_id');

        try {                  
            const main_action_url = "https://insidearbitrage.com/cct-scripts/money_managers.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
              user_id: cct_user_id,
              request_type: "fetch_managers"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ addedManagers: ajaxResponse });
            this.setState({pageLoaded: true})
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleSuggestionSelected = (selectedValue, callback) => {
        const manager_id = selectedValue.company_id;
        const manager_name = selectedValue.label;

        this.addManager(manager_id, manager_name).then(() => {
          if (callback) {
            callback(); 
          }
        });
    }

    addManager = async(manager_id, manager_name) => {
        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({adding_manager: true})

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/money_managers.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                manager_id: manager_id,
                manager_name: manager_name,
                request_type: "add_manager"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, notification_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: "",
                        adding_manager: false
                    });
                } else {
                    if(successMsg !== "" && notification_status === "manager_added"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            adding_manager: false
                        });      
                        this.fetchData();               
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            adding_manager: false
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    deleteManager = async(manager_id) => {
        if (this.state.deleting) {
            return;
        }

        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({
            deleting: true, 
            manager_to_delete: manager_id
        });

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/money_managers.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                manager_id: manager_id,
                request_type: "delete_manager"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, notification_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        displayError: false,
                        displaySuccess: false,
                        errorMsg: "",
                        successMsg: "",
                        deleting: false
                    });
                } else {
                    if(successMsg !== "" && notification_status === "manager_deleted"){
                        this.setState({
                            displayError: false,
                            displaySuccess: false,
                            errorMsg: "",
                            successMsg: "",
                            deleting: false
                        });      
                        this.fetchData();               
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            deleting: false
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    render(){
        document.title = "Money Managers - Conference Call Transcripts";

        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        const{ page_name, displaySuccess, displayError, successMsg, errorMsg, pageLoaded, addedManagers, manager_to_delete, adding_manager, deleting } = this.state;
        const{ handleSuggestionSelected, deleteManager } = this;

        if(cct_username === "" && cct_password === ""){
            const redirect_url = `${window.location.origin}/my-account`;
            window.location.href = redirect_url;
        } else {
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">

                <MyAccountMenu page_name={page_name} />

                <div className="row">
                <div className="col-lg-12">
                <h4>Money Managers</h4>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <div className="homepage-search-area d-flex justify-content-center pt-3 pb-3 mt-2">
                <AutosuggestComponent id="manager-search" onSuggestionSelected={handleSuggestionSelected} />
                </div>
                </div>
                </div>

                <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div className="row mb-2" style={adding_manager ? {display: 'block'} : {display: 'none'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
                <div className="row">
                <div className="col-lg-12">
                
                <div className="alert alert-success mt-3 mb-2" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>

                <div className="alert alert-danger mt-3 mb-2" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>

                </div>
                </div>

                <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                <table className='portfolio-data-table'>
                <thead className='stickyhead'>
                <tr>
                <th>Name</th>
                <th>Latest Filing</th>
                <th>Delete</th>
                </tr>
                </thead>

                <tbody>
                {addedManagers.length !== 0 ? (
                    addedManagers.map((row, rowIndex) => {
                        let id = row.id;
                        let money_manager_id = row.money_manager_id;
                        let money_manager_name = row.money_manager_name;
                        let cik = row.cik;
                        let latest_filing_date = row.latest_filing_date;
                        if(latest_filing_date !== ""){
                            latest_filing_date = formatDate(latest_filing_date, "M j, Y")
                        }
                        let latest_filing_url = row.latest_filing_url;
                        if(latest_filing_date !== "" && latest_filing_url !== ""){
                            latest_filing_date = <><a href={latest_filing_url} target="_blank" ref="nofollow">{latest_filing_date}</a></>
                        }
                        let url_string = cik + ".MGR";

                        return(
                            <tr key={`manager-item-${rowIndex}`}>
                            <td><a href={`/?co=${url_string}`}>{money_manager_name}</a></td>
                            <td>{latest_filing_date}</td>
                            <td align="center"><img src={AjaxLoaderImg} style={manager_to_delete === money_manager_id && deleting ? {display:'block'} : {display:'none'}} /> {<button className="delete-btn-portfolio" style={manager_to_delete == money_manager_id && deleting ? {display:'none'} : {display:'block'}} onClick={() => deleteManager(money_manager_id)}><i className="fa-solid fa-trash-can" aria-hidden="true"></i></button>}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                    <td colSpan={3}><center>No Data Found.</center></td>
                    </tr>
                )}
                </tbody>
                </table>
                </div>
                </div>
                </div>

                </div>
                </div>
            )
        }
    }

}

export default MoneyManagers;