import { Component, Fragment } from 'react';

class DebenturesMain extends Component {
    constructor(){
        super();

        this.state = {
            pageLoaded: false,
            data: [],
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            pageNum: "1",
            sortBy: "percent_change",
            sortDir: "desc",
            processUserAction: false
        }
    }

    async componentDidMount() {
        try {                  
          const main_action_url = "https://insidearbitrage.com/cct-scripts/debentures.php"; 
          const apiKey = this.state.apiKey;
          const postData = {
            pageNum: this.state.pageNum,
            sortBy: this.state.sortBy,
            sortDir: this.state.sortDir
          }
          const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }         
          const response = await fetch(main_action_url, requestOptions);
          const ajaxResponse = await response.json();
          this.setState({ data: ajaxResponse });
          this.setState({pageLoaded: true})
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

    handleSort = async (column_to_sort) => {
        let sort_order = this.state.sortDir;

        if(sort_order == "asc"){
            sort_order = "desc";
        } else if(sort_order == "desc"){
            sort_order = "asc";
        }

        this.setState({processUserAction: true})

        try {                  
            const main_action_url = "https://insidearbitrage.com/cct-scripts/debentures.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
              pageNum: "1",
              sortBy: column_to_sort,
              sortDir: sort_order
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ data: ajaxResponse });
            this.setState({ pageNum: "1" });
            this.setState({ sortBy: column_to_sort });
            this.setState({ sortDir: sort_order });
            this.setState({ processUserAction: false });

            const pageContent = document.getElementById('page-content');
            if (pageContent) {
                const elementOffsetTop = pageContent.offsetTop - 300;
                window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    handlePagination = async(e, pagination_type, total_pages) => {
        e.preventDefault();

        const column_to_sort = this.state.sortBy;
        const sort_order = this.state.sortDir;

        let current_page_num = this.state.pageNum;
        let page_to_jump = 0;

        if(pagination_type === "first"){
            page_to_jump = 1;
        } else if(pagination_type === "last"){
            page_to_jump = total_pages;
        } else if(pagination_type === "next"){
            if(current_page_num !== total_pages){
                page_to_jump = parseInt(current_page_num) + 1;
            } else {
                page_to_jump = total_pages;
            }
        } else if(pagination_type === "previous"){
            if(current_page_num !== 1){
                page_to_jump = parseInt(current_page_num) - 1;
            } else {
                page_to_jump = 1;
            }
        }

        this.setState({processUserAction: true})

        try {                  
            const main_action_url = "https://insidearbitrage.com/cct-scripts/debentures.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
              pageNum: page_to_jump,
              sortBy: column_to_sort,
              sortDir: sort_order
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ data: ajaxResponse });
            this.setState({ pageNum: page_to_jump });
            this.setState({ processUserAction: false });

            const pageContent = document.getElementById('page-content');
            if (pageContent) {
                const elementOffsetTop = pageContent.offsetTop - 300;
                window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    render(){
        document.title = 'Conference Call Transcripts - Debentures';

        const{pageLoaded, data, sortBy, sortDir, processUserAction} = this.state;
        const{handleSort, handlePagination} = this;
        
        let total_counts = "";
        let result_counts = "";
        let total_pages = "";
        let current_page = "";
        let deb_count = 0;

        return (
            <div id="page-content" className="mt-1 mb-5">

            <div className="container">
            <div className="row">
            <div className="col-lg-12">
            <h1 className="entry-title">Debentures</h1>
            </div>
            </div>
            

            <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
            <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">

            <div className='portfolio-data-table-loader' style={processUserAction ? {display: 'block'} : {display: 'none'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border portfolio-data-table-loader-spinner" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <table className='portfolio-data-table' width="100%">
            <thead className='stickyhead'>
            <tr>
            <th onClick={() => handleSort('ticker')}>Ticker <span className='sort-icon' style={sortBy === 'ticker' ? {display:'block'} : {display:'none'}}>{sortBy === 'ticker' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell" onClick={() => handleSort('name')}>Name <span className='sort-icon' style={sortBy === 'name' ? {display:'block'} : {display:'none'}}>{sortBy === 'name' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th onClick={() => handleSort('price')}>Price <span className='sort-icon' style={sortBy === 'price' ? {display:'block'} : {display:'none'}}>{sortBy === 'price' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th onClick={() => handleSort('price_change')}>Change <span className='sort-icon' style={sortBy === 'price_change' ? {display:'block'} : {display:'none'}}>{sortBy === 'price_change' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th onClick={() => handleSort('percent_change')}>Change % <span className='sort-icon' style={sortBy === 'percent_change' ? {display:'block'} : {display:'none'}}>{sortBy === 'percent_change' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th onClick={() => handleSort('coupon_rate')}>Coupon Rate <span className='sort-icon' style={sortBy === 'coupon_rate' ? {display:'block'} : {display:'none'}}>{sortBy === 'coupon_rate' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th onClick={() => handleSort('market_cap')}>Market Cap <span className='sort-icon' style={sortBy === 'market_cap' ? {display:'block'} : {display:'none'}}>{sortBy === 'market_cap' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            <th className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell" onClick={() => handleSort('volume')}>Volume <span className='sort-icon' style={sortBy === 'volume' ? {display:'block'} : {display:'none'}}>{sortBy === 'volume' && sortDir === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
            </tr>
            </thead>

            {data.length !== 0 ? (
                data.map((row) => {
                    total_counts = parseInt(row.total);
                    result_counts = parseInt(row.counts);
                    total_pages = parseInt(row.total_pages);
                    current_page = parseInt(row.current_page);
                    let debs_data = row.debs;

                    return(
                        <tbody key={`debentures`}>
                        {total_counts === 0 || result_counts === 0 ? (
                            <tr>
                            <td colSpan={8}><center>No Data Found.</center></td>
                            </tr>
                        ) : (
                            debs_data.map((deb_item, debIndex) => {
                                deb_count++;
                                let ticker = deb_item.ticker;
                                let name = deb_item.name;
                                let price = deb_item.price;
                                let price_change = deb_item.price_change;
                                let percent_change = deb_item.percent_change + "%";
                                let coupon_rate = deb_item.coupon_rate;
                                if(coupon_rate === "0" || coupon_rate === "0.00"){
                                    coupon_rate = "N/A";
                                } else {
                                    coupon_rate = coupon_rate + "%";
                                }
                                let market_cap = deb_item.market_cap;
                                let volume = deb_item.volume;
                                let tr_class = "odd";
                                if(deb_count % 2 === 0){
                                    tr_class = "even";
                                }

                                return (
                                  <tr key={`debenture-item-${debIndex}`} className={tr_class}>
                                    <td>{ticker}</td>
                                    <td className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell">{name}</td>
                                    <td>{price}</td>
                                    <td>{price_change}</td>
                                    <td>{percent_change}</td>
                                    <td>{coupon_rate}</td>
                                    <td>{market_cap}</td>
                                    <td className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell">{volume}</td>
                                  </tr>
                                );
                              })
                        )}
                        </tbody>
                    )
                })
            ):(
                <tbody>
                <tr>
                <td colSpan={8}><center>No Data Found.</center></td>
                </tr>
                </tbody>
            )}
            </table>
            </div>
            </div>

            <div className="row mt-4" style={total_counts === 0 || total_pages === 0 || ((total_pages === current_page && total_pages === 1) && total_pages !== "" && current_page !== "") ? {display:'none'} : {display:'flex'}}>
            <div className="col-lg-12">
            <nav>
            <ul className="pagination justify-content-center">
                <li className={current_page === 1 && current_page !== "" ? "page-item disabled" : "page-item"}><a className="page-link" href="#" onClick={(e) => handlePagination(e, 'first', total_pages)}>First</a></li>
                <li className={current_page === 1 && current_page !== "" ? "page-item disabled" : "page-item"}><a className="page-link" href="#" onClick={(e) => handlePagination(e, 'previous', total_pages)}>Previous</a></li>
                <li className={current_page === total_pages && current_page !== "" && total_pages != "" ? "page-item disabled" : "page-item"}><a className="page-link" href="#" onClick={(e) => handlePagination(e, 'next', total_pages)}>Next</a></li>
                <li className={current_page === total_pages && current_page !== "" && total_pages != "" ? "page-item disabled" : "page-item"}><a className="page-link" href="#" onClick={(e) => handlePagination(e, 'last', total_pages)}>Last</a></li>
            </ul>
            </nav>
            </div>
            </div>

            </div>

            </div>    
            </div>
        );
    }
}

export default DebenturesMain;