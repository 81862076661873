import { Component, Fragment } from "react";
import { formatDate } from "../utils/general.utils";

class CompanyFilings extends Component {
    constructor(props){
        super(props);

        this.state = {
            companyId: props.companyId,
            companyTicker: props.companyTicker,
            companyCountry: props.companyCountry,
            tabLoaded: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            data: [],
            isLoadMore: false,
            page: 1,
        }
        this.pageSize = 100;
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.state.isLoadMore) return; 
    
        this.setState({ isLoadMore: true });
    
        try {
          const {companyId, page} = this.state;
          const {pageSize} = this;
          const main_action_url = "https://insidearbitrage.com/cct-scripts/get_company_filings.php"; 
          const apiKey = this.state.apiKey;
          const postData = {
            company_id: this.state.companyId,
            rpp: pageSize,
            pn: page
          }
          const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }
          const response = await fetch(main_action_url, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const newData = await response.json();
    
          this.setState((prevState) => ({
            data: [...prevState.data, ...newData],
            page: prevState.page + 1,
            isLoadMore: false,
          }));
        } catch (error) {
          console.error('Error fetching data: ', error);
          this.setState({ isLoadMore: false });
        }
    };        

    render(){
        const{companyId, companyTicker, companyCountry, data, tabLoaded, isLoadMore} = this.state;
        const {fetchData} = this;

        let total_filings = "";
        let total_pages = "";
        let current_page = "";

        return(
            <div className="mt-3">
            
            <div className="row" style={tabLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={tabLoaded ? {display: 'block'} : {display: 'none'}}>
            
            <div className="row">
            <div className="col-lg-12">
            <h3>Recent Filings</h3>
            </div>
            </div>

            {data.length !== 0 ? (
              data.map((row, index) => {
                total_filings = parseInt(row.total_filings);
                total_pages = parseInt(row.total_pages);
                current_page = parseInt(row.current_page);
                let filing_items = row.filings;
                //console.log(total_pages);
        
                return (
                  total_filings === 0 || total_pages === 0 ? (
                    <div key={index} className="row">
                      <div className='col-lg-12'>Sorry, no filings on record</div>
                    </div>
                  ) : (
                    filing_items.map((filing_item, filingIndex) => {
                      let filing_id = filing_item.id;
                      let filing_date = filing_item.filing_date;
                      let filing_title = filing_item.filing_title;
                      let filing_headline = filing_item.filing_headline;
                      if(filing_headline === null || filing_headline === undefined){
                        filing_headline = "";
                      }
                      let filing_article = filing_item.filing_article;
                      if(filing_article === null || filing_article === undefined){
                        filing_article = "";
                      }
                      let filing_url = filing_item.filing_url;

                      let filing_text = filing_title;
                      if(filing_headline !== "" && filing_article !== ""){
                        filing_text = filing_headline;
                      }
        
                      return (
                          <Fragment key={`filing-item-${filingIndex}`}>
                          <div className='row pb-2 mb-2'>
                          <div className='col-lg-12'>
                          <div className="border-bottom">
                          <div className='row mb-2'>
                          <div className='col-lg-12'>
                          <a href={filing_url} target='_blank' rel="nofollow">{filing_text}</a>
                          </div>
                          </div> 

                          <div className='d-flex flex-row stock-news-info mb-2'>
                          {formatDate(filing_date, "M j, Y")}                                                    
                          </div> 
                          </div>
                          </div>
                          </div>
                          </Fragment>
                      );
                    })
                  )
                );
              })
            ) : (
              <div className="row" style={isLoadMore ? {display:'none'} : {display:'block'}}>
                <div className='col-lg-12'>Sorry, no filings on record</div>
              </div>
            )}

            <div className='row' style={total_filings === 0 || total_pages === 0 || (total_pages === current_page && total_pages !== "" && current_page !== "") ? {display:'none'} : {display:'block'}}>
              <div className='col-lg-12'>
                <button className="loadmore-btn" onClick={() => fetchData()} disabled={isLoadMore}>
                  {isLoadMore ? 'Loading...' : 'Load More'} 
                </button>
              </div>
            </div>

            </div>

            </div>
        )
    }
}

export default CompanyFilings;