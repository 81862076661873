import { Component } from "react";
import { formatDate } from "../utils/general.utils";

class IndividualMoneyManager extends Component {
    constructor(props){
        super(props);

        this.state = {
            symbol: props.symbol,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            pageLoaded: false,
            manager_id: "",
            manager_name: "",
            manager_cik: "", 
            famous_person: "", 
            as_of_date: "", 
            date_reported: "", 
            portfolio_size: "",
            holdings: [],
            manager_found: true
        }
    }

    async componentDidMount() {
        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/money_managers.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                symbol: this.state.symbol,
                request_type: 'fetch_individual_manager'
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();          

            if (ajaxResponse.length > 0) {
                const { manager_id, manager_name, manager_cik, famous_person, as_of_date, date_reported, portfolio_size, holdings } = ajaxResponse[0];
                document.title = `${manager_name} - Conference Call Transcripts`;

                this.setState({
                    manager_id: manager_id,
                    manager_name: manager_name,
                    manager_cik: manager_cik, 
                    famous_person: famous_person, 
                    as_of_date: as_of_date, 
                    date_reported: date_reported, 
                    portfolio_size: portfolio_size,
                    holdings: holdings
                });
            } else {
                this.setState({ manager_found: false });
            }
            this.setState({pageLoaded: true})
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    render(){
        const { pageLoaded, manager_id, manager_name, manager_cik, famous_person, as_of_date, date_reported, portfolio_size, holdings, manager_found  } = this.state;

        if(manager_found) { 
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">
                
                <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
                
                <div className="row">
                <div className="col-lg-12">
                <h1 className="entry-title">{manager_name} {famous_person && `(${famous_person})`}</h1>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <h5 className="text-center">Most recent update: {formatDate(as_of_date, "F d, Y")}</h5>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                
                <table className='portfolio-data-table' width="100%">
                <thead className='stickyhead'>
                <tr>
                <th>Ticker</th>
                <th>Company</th>
                <th>Shares/Warrants</th>
                <th>Value ($000)</th>
                </tr>
                </thead>

                <tbody>
                {holdings.length !== 0 ? (
                    holdings.map((holding, holdingIndex) => {
                        let holding_id = holding.holding_id;
                        let ticker = holding.ticker;
                        let company = holding.company;
                        let company_country = holding.company_country;
                        let company_id = holding.company_id;
                        let num_shares = holding.num_shares;
                        if(num_shares !== ""){
                            num_shares = parseInt(num_shares).toLocaleString();
                        }
                        let holding_value = holding.holding_value;
                        if(holding_value !== ""){
                            holding_value = parseInt(holding_value).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
                        }
                        let holding_percentage = holding.holding_percentage;
                        let url_string = ticker + "." + company_country;

                        return(
                            <tr key={`holding-item-${holdingIndex}`}>
                            <td><a href={`/?co=${url_string}`}>{ticker}</a></td>
                            <td><a href={`/?co=${url_string}`}>{company}</a></td>
                            <td>{num_shares}</td>
                            <td>{holding_value}</td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                    <td colSpan={4}><center>No holdings found.</center></td>
                    </tr>
                )}
                </tbody>
                </table>

                </div>
                </div>

                </div>                

                </div>
                </div>
            )
        } else {
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">
                
                <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
                <div className="row">
                <div className="col-lg-12">
                <div className="alert alert-danger" role="alert">
                Error: Cannot find the Money Manager, please try again
                </div>
                </div>
                </div>
                </div>

                </div>
                </div>
            )
        }
    }
}

export default IndividualMoneyManager;