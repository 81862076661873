import { Component, Fragment } from "react";
import MyAccountMenu from "../components/myAccountMenu.component";
import AutosuggestComponent from "../components/autosuggest.component";
import ModalFormComponent from "../components/modalForm.component";
import ReactHtmlParser from 'react-html-parser';
import { getCookieValue, sortTable } from "../utils/general.utils";
import AjaxLoaderImg from '../assets/img/ajax-loader-small.gif';

class MyNotifications extends Component {
    constructor(){
        super();
       
        this.state = {
            page_name: "companies",
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            addedSymbols: [],
            pageLoaded: false,
            company_to_delete: "",
            adding_symbol: false,
            transcripts_notifications: {},
            transcripts_notifications_process: {},
            filings_notifications: {},
            filings_notifications_process: {},
            sortColumn: '',
            sortOrder: '',
            showModal: false,
            updatedNotes: {},
            formType: 'unknown',
            modalCompanyId: '0',
            updatedSites: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    handleSuggestionSelected = (selectedValue, callback) => {
        //alert("triggered");
        //console.log('Autosuggest value changed:', selectedValue);
        //console.log(selectedValue.company_id);

        const company_id = selectedValue.company_id;
        const company_symbol = selectedValue.id;

        this.addSymbol(company_id, company_symbol).then(() => {
          if (callback) {
            callback(); 
          }
        });
    }

    addSymbol = async(company_id, company_symbol) => {
        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({adding_symbol: true})

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                company_id: company_id,
                company_symbol: company_symbol,
                request_type: "add"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, notification_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: "",
                        adding_symbol: false
                    });
                } else {
                    if(successMsg !== "" && notification_status === "symbol_added"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            adding_symbol: false
                        });      
                        this.fetchData();               
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            adding_symbol: false
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    fetchData = async () => {
        const cct_user_id = getCookieValue('cct_user_id');

        try {                  
            const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
              user_id: cct_user_id,
              request_type: "fetch_data"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            //this.setState({ addedSymbols: ajaxResponse });
            if(this.state.sortColumn === ""){
                this.setState({sortColumn: 'company_ticker'});
                this.setState({sortOrder: 'asc'});
                const sortedData = sortTable(ajaxResponse, 'company_ticker', 'string', 'asc');
                this.setState({addedSymbols: sortedData})
            } else {
                const sortedData = sortTable(ajaxResponse, this.state.sortColumn, 'string', this.state.sortOrder);
                this.setState({addedSymbols: sortedData})
            }
            this.setState({pageLoaded: true})
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    deleteSymbol = async(company_id) => {
        if (this.state.deleting) {
            return;
        }

        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({
            deleting: true, 
            company_to_delete: company_id
        });

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                company_id: company_id,
                request_type: "delete"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, notification_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        displayError: false,
                        displaySuccess: false,
                        errorMsg: "",
                        successMsg: "",
                        deleting: false
                    });
                } else {
                    if(successMsg !== "" && notification_status === "symbol_deleted"){
                        this.setState({
                            displayError: false,
                            displaySuccess: false,
                            errorMsg: "",
                            successMsg: "",
                            deleting: false
                        });      
                        this.fetchData();               
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            deleting: false
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleTranscriptNotifications = async (event) => {
        const { name, checked, value } = event.target;
        const cct_user_id = getCookieValue('cct_user_id');

        this.setState(prevState => ({
          transcripts_notifications_process: {
            ...prevState.transcripts_notifications_process,
            [value]: true,
          },
        }));
      
        try {
          const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
          const apiKey = this.state.apiKey;
          const postData = {
            user_id: cct_user_id,
            company_id: value,
            transcripts: checked,
            request_type: "process_transcripts"
          }
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }         
          const response = await fetch(main_action_url, requestOptions);
          const ajaxResponse = await response.json();
      
          this.setState(prevState => ({
            transcripts_notifications_process: {
              ...prevState.transcripts_notifications_process,
              [value]: false,
            },
          }));
      
        } catch (error) {
          console.error('Error fetching data:', error);
          this.setState(prevState => ({
            transcripts_notifications_process: {
              ...prevState.transcripts_notifications_process,
              [value]: false,
            },
          }));
        }
      
        this.setState(prevState => ({
          transcripts_notifications: {
            ...prevState.transcripts_notifications,
            [value]: checked,
          },
        }));
    };

    handleFilingsNotifications = async (event) => {
        const company_id = event.target.getAttribute('data-company-id');
        let filing_type = event.target.getAttribute('data-filing-type');

        const cct_user_id = getCookieValue('cct_user_id');

        if(filing_type === "ALL"){
            filing_type = "Buys/Sells";
        } else if(filing_type === "Buys/Sells"){
            filing_type = "BUYS";
        } else if(filing_type === "BUYS"){
            filing_type = "None";
        } else {
            filing_type = "ALL";
        }

        this.setState(prevState => ({
           filings_notifications_process: {
            ...prevState.filings_notifications_process,
            [company_id]: true,
          },
        }));

        try {
          const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
          const apiKey = this.state.apiKey;
          const postData = {
            user_id: cct_user_id,
            company_id: company_id,
            filing_type: filing_type,
            request_type: "process_filings"
          }
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }         
          const response = await fetch(main_action_url, requestOptions);
          const ajaxResponse = await response.json();
      
          this.setState(prevState => ({
            filings_notifications_process: {
              ...prevState.filings_notifications_process,
              [company_id]: false,
            },
          }));
      
        } catch (error) {
          console.error('Error fetching data:', error);
          this.setState(prevState => ({
            filings_notifications_process: {
              ...prevState.filings_notifications_process,
              [company_id]: false,
            },
          }));
        }

        this.setState(prevState => ({
           filings_notifications: {
             ...prevState.filings_notifications,
             [company_id]: filing_type,
           },
        }));
    }

    handleSort = (column_to_sort, data_type) =>{
        const data_to_sort = this.state.addedSymbols;
        let sort_order = this.state.sortOrder;
        if(sort_order == "asc"){
            sort_order = "desc";
        } else if(sort_order == "desc"){
            sort_order = "asc";
        }

        const sortedData = sortTable(data_to_sort, column_to_sort, data_type, sort_order);
        this.setState({addedSymbols: sortedData});
        this.setState({sortColumn: column_to_sort});
        this.setState({sortOrder: sort_order});
    }

    openModal = (company_id, form_type) => {
      this.setState({ 
        modalCompanyId: company_id,
        formType: form_type,
        showModal: true 
      });
    };
  
    closeModal = () => {
      this.setState({ 
        formType: 'unknown',
        modalCompanyId: '0',
        showModal: false 
      });
    };

    handleFormSubmit = (formData) => {
      //this.setState({ updatedNotes: formData });
      if(this.state.formType === "notesForm"){
        let updated_company_id = formData.updated_company_id;
        let notes = formData.notes;

        if (notes !== null && notes.trim() !== "") {
          notes = "added";
        } else {
          notes = "not_added";
        }

        this.setState(prevState => ({
          updatedNotes: {
            ...prevState.updatedNotes,
            [updated_company_id]: notes,
          }
        }));
      
        this.closeModal();
      }

      if(this.state.formType === "sitesForm"){
        //this.setState({ updatedSites: formData })
        let updated_company_id = formData.updated_company_id;
        let sites = formData.sites;

        this.setState(prevState => ({
          updatedSites: {
            ...prevState.updatedSites,
            [updated_company_id]: sites,
          }
        }));
      }
    };

    render(){
        document.title = "Your Notifications - Conference Call Transcripts";

        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        const{ page_name, displaySuccess, displayError, successMsg, errorMsg, pageLoaded, addedSymbols, company_to_delete, adding_symbol, sortColumn, sortOrder, deleting } = this.state;
        const{transcripts_notifications, transcripts_notifications_process} = this.state;
        const{filings_notifications, filings_notifications_process} = this.state;
        const { showModal, formType, modalCompanyId, updatedNotes, updatedSites } = this.state;
        const{ handleSuggestionSelected, deleteSymbol, handleTranscriptNotifications, handleFilingsNotifications, handleSort, openModal, closeModal, handleFormSubmit } = this;

        //console.log(updatedSites)

        let symbols_count = 0;

        if(cct_username === "" && cct_password === ""){
            const redirect_url = `${window.location.origin}/my-account`;
            window.location.href = redirect_url;
        } else {
            return(
              <Fragment>
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">

                <MyAccountMenu page_name={page_name} />

                <div className="row">
                <div className="col-lg-12">
                <h4>Your Notifications</h4>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <div className="homepage-search-area d-flex justify-content-center pt-3 pb-3 mt-2">
                <AutosuggestComponent id="notifications-search" onSuggestionSelected={handleSuggestionSelected} />
                </div>
                </div>
                </div>

                <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div className="row mb-2" style={adding_symbol ? {display: 'block'} : {display: 'none'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
                <div className="row">
                <div className="col-lg-12">
                
                <div className="alert alert-success mt-3 mb-2" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>

                <div className="alert alert-danger mt-3 mb-2" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>

                </div>
                </div>

                <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                <table className='portfolio-data-table'>
                <thead className='stickyhead'>
                <tr>
                <th onClick={() => handleSort('company_ticker', 'string')}>Ticker <span className='sort-icon' style={sortColumn === 'company_ticker' ? {display:'block'} : {display:'none'}}>{sortColumn === 'company_ticker' && sortOrder === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
                <th className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell" onClick={() => handleSort('company_name', 'string')}>Company <span className='sort-icon' style={sortColumn === 'company_name' ? {display:'block'} : {display:'none'}}>{sortColumn === 'company_name' && sortOrder === 'asc' ? (<i className="fa-solid fa-caret-up"></i>) : (<i className="fa-solid fa-caret-down"></i>)}</span></th>
                <th>Filings</th>
                <th>Transcripts</th>
                <th>Notes</th>
                <th className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell">Websites</th>
                <th>Delete</th>
                </tr>
                </thead>

                <tbody>
                {addedSymbols.length !== 0 ? (
                    addedSymbols.map((row, rowIndex) => {
                        symbols_count++;
                        let notification_id = row.notification_id;
                        let company_id = row.company_id;
                        let company_ticker = row.company_ticker;
                        let company_country = row.company_country;
                        let company_name = row.company_name;
                        let transcripts = row.transcripts;
                        if(transcripts === "t"){
                            transcripts = true;
                        } else {
                            transcripts = false;
                        }
                        let filing_types = row.filing_types;
                        if(filings_notifications && filings_notifications[company_id]){
                            filing_types = filings_notifications[company_id];
                        }
                        if(filing_types === "4"){
                            filing_types = "Buys/Sells";
                        }
                        let filing_type_btn_class = "btn btn-small btn-success";
                        if(filing_types === "ALL"){
                            filing_type_btn_class = "btn btn-small btn-success";
                        } else if(filing_types === "None"){
                            filing_type_btn_class = "btn btn-small btn-danger";
                        } else if(filing_types === "Buys/Sells"){
                            filing_type_btn_class = "btn btn-small btn-primary";
                        } else if(filing_types === "BUYS"){
                            filing_type_btn_class = "btn btn-small btn-info";
                        }
                        let notes = row.notes;
                        if (notes !== null && notes.trim() !== "") {
                            notes = "added";
                        } else {
                            notes = "not_added";
                        }
                        if(updatedNotes && updatedNotes[company_id]){
                          notes = updatedNotes[company_id];
                        }
                        let notes_class = "have-no-notes-btn-portfolio";
                        if(notes === "added"){
                          notes_class = "have-notes-btn-portfolio";
                        }
                        let sites = row.sites;
                        if(updatedSites && updatedSites[company_id]){
                          sites = updatedSites[company_id];
                        }
                        let sites_class = "have-no-web-btn-portfolio"
                        if(sites === "have_sites"){
                          sites_class = "have-web-btn-portfolio"
                        }
                        let target_url = company_ticker + "." + company_country;
                        let tr_class = "odd";
                        if(symbols_count % 2 === 0){
                            tr_class = "even";
                        }

                        return(
                            <tr key={`notification-item-${rowIndex}`} className={tr_class}>
                            <td><a href={`/?co=${target_url}`}>{company_ticker}</a></td>
                            <td className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell">{company_name}</td>
                            <td><img src={AjaxLoaderImg} style={filings_notifications_process[company_id] ? {display:'block'} : {display:'none'}} /><button className={filing_type_btn_class} data-filing-type={filing_types} data-company-id={company_id} onClick={(event) => handleFilingsNotifications(event)} style={filings_notifications_process[company_id] ? {display:'none'} : {display:'block'}}>{filing_types}</button></td>
                            <td valign="middle" align="center"><img src={AjaxLoaderImg} style={transcripts_notifications_process[company_id] ? {display:'block'} : {display:'none'}} /><input type="checkbox" name={`transcripts-${company_id}`} id={`transcripts-${company_id}`} value={company_id} onClick={(event) => handleTranscriptNotifications(event)} defaultChecked={transcripts || transcripts_notifications[company_id]} style={transcripts_notifications_process[company_id] ? {display:'none'} : {display:'block'}} /></td>
                            <td valign="middle" align="center"><button className={notes_class} onClick={() => openModal(company_id, 'notesForm')}><i className="fa-solid fa-book"></i></button></td>
                            <td className="d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell" valign="middle" align="center"><button className={sites_class} onClick={() => openModal(company_id, 'sitesForm')}><i className="fa-solid fa-earth-americas"></i></button></td>
                            <td valign="middle" align="center"><img src={AjaxLoaderImg} style={company_to_delete === company_id && deleting ? {display:'block'} : {display:'none'}} /> {<button className="delete-btn-portfolio" style={company_to_delete == company_id && deleting ? {display:'none'} : {display:'block'}} onClick={() => deleteSymbol(company_id)}><i className="fa-solid fa-trash-can" aria-hidden="true"></i></button>}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                    <td colSpan={6}><center>No Data Found.</center></td>
                    </tr>
                )}
                </tbody>
                </table>
                </div>
                </div>
                </div>

                </div>
                </div>

              <div className={`modal fade${showModal ? " show" : ""}`} id="portfolio-modal-popup" data-bs-backdrop="static" data-bs-keyboard="false"  tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: showModal ? "block" : "none" }}>
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header mb-3">
                      <button type="button" className="close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ModalFormComponent companyId={modalCompanyId} formType={formType} onSubmit={handleFormSubmit}/>
                  </div>
                </div>
              </div>
              <div className={`modal-backdrop${showModal ? " show" : ""}`} style={{ display: showModal ? "block" : "none" }}></div>
              </Fragment>
            )
        }
    }
}

export default MyNotifications