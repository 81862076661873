import { Component } from "react";
import MyAccountMenu from "../components/myAccountMenu.component";
import AutosuggestComponent from "../components/autosuggest.component";
import ReactHtmlParser from 'react-html-parser';
import { getCookieValue } from "../utils/general.utils";
import AjaxLoaderImg from '../assets/img/ajax-loader-small.gif';

class SiteUpdates extends Component {
    constructor(){
        super();

        this.state = {
            page_name: "siteUpdates",
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            showBtn: true,
            pageLoaded: false,
            site_to_delete: "",
            sites: [],
            formData: {
                site_name: "",
                site_url: "",
                site_company: ""
            }
        }
    }

    componentDidMount() {
        this.fetchSites();
    }

    fetchSites = async() => {
        const cct_user_id = getCookieValue("cct_user_id");

        try {                  
            const main_action_url = "https://insidearbitrage.com/cct-scripts/site_updates.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                request_type: "get_sites"
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Cct-Access': apiKey
                },
                body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ sites: ajaxResponse });
            this.setState({ pageLoaded: true });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    deleteSite = async(site_id) => {
        if (this.state.deleting) {
            return;
        }

        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({
            deleting: true, 
            site_to_delete: site_id
        });

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                site_id: site_id,
                request_type: "delete_site"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, site_delete_status, have_sites } = ajaxResponse[0];
                let site_actions = {};
                
                if(errorMsg !== ""){
                    this.setState({
                        displayError: false,
                        displaySuccess: false,
                        errorMsg: "",
                        successMsg: "",
                        deleting: false
                    });
                } else {
                    if(successMsg !== "" && site_delete_status === "site_deleted"){
                        this.setState({
                            displayError: false,
                            displaySuccess: false,
                            errorMsg: "",
                            successMsg: "",
                            deleting: false
                        });  
                        
                        this.fetchSites();             
                        //this.setState({ deleting: false }) 
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            deleting: false
                        });
                    }
                }
            }

            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleSubmit = async(e) => {
        e.preventDefault();

        const cct_user_id = getCookieValue("cct_user_id");

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        const{ site_name, site_url, site_company } = this.state.formData;

        try {                
            const main_action_url = "https://insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                site_name: site_name,
                site_url: site_url,
                company_id: site_company,
                request_type: "update_sites"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();      
            
            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, site_update_status, sites } = ajaxResponse[0];
                if(errorMsg !== ""){
                    this.setState({
                        showBtn: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: ""
                    })
                } else {
                    if(successMsg !== "" && site_update_status === "updated"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            showBtn: true,
                            clearAutosuggest: true,
                            formData:{
                                site_name: "",
                                site_url: "",
                                site_company: ""
                            }
                        });     
                        this.fetchSites();
                    } else {
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: ""
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleCompanyChange = (selectedCompany) => {
        this.setState((prevState) => ({
            formData: {
            ...prevState.formData,
            site_company: selectedCompany,
            },
        }));
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
        formData: {
            ...prevState.formData,
            [name]: value,
        },
        }));
    };

    render(){
        document.title = "Site Updates - Conference Call Transcripts";

        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        const{ page_name, displayError, displaySuccess, errorMsg, successMsg, showBtn, pageLoaded, sites, site_to_delete, deleting } = this.state;
        const { handleChange, deleteSite, handleSubmit, handleCompanyChange } = this;

        if(cct_username === "" && cct_password === ""){
            const redirect_url = `${window.location.origin}/my-account`;
            window.location.href = redirect_url;
        } else {
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">

                <MyAccountMenu page_name={page_name} />

                <div className="row">
                <div className="col-lg-12">
                <h4 className="text-center">Receive an alert when a website changes!</h4>
                </div>
                </div>

                <div className="row mt-3">
                <div className="col-md-4 offset-md-4 col-12">

                <div className="row">
                <div className="col-lg-12">
                <div className="alert alert-success mb-2" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>
                <div className="alert alert-danger mb-2" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>
                </div>
                </div>

                <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="site_name"><b>Name it</b></label>
                <input type="text" className="form-control" id="site_name" name="site_name"  onChange={handleChange} value={this.state.formData.site_name} />
                <small id="site_nameHelp" className="form-text text-muted">(e.g. Chairman's Letter, monthly sales etc)</small>
                </div>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="site_url"><b>URL</b></label>
                <input type="text" className="form-control" id="site_url" name="site_url"  onChange={handleChange} value={this.state.formData.site_url} />
                <small id="site_urlHelp" className="form-text text-muted">(You will be notified when there are changes to this page)</small>
                </div>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <div className="form-group siteupdates-search-area">
                <label htmlFor="site_company"><b>Company associated with this site </b></label>
                <AutosuggestComponent id="site-search" name="site_company" field_class="form-control" onCompanyChange={handleCompanyChange} clearValue={this.state.clearAutosuggest} />
                <small id="site_companyHelp" className="form-text text-muted">(If none, leave blank)</small>
                </div>
                </div>
                </div>

                <div className="form-group mt-3 d-flex justify-content-center">
                <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
                <div className="d-flex" >
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>

                
                <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Add It</button>
                </div>
                </form>

                </div>
                </div>

                <div className="row mt-3" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div className="mt-4" style={pageLoaded ? {display: 'block'} : {display: 'none'}}>

                <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                <table className='portfolio-data-table' width="100%" style={{tableLayout: "fixed"}}>
                <thead>
                <tr>
                <th width="20%">Site Title</th>
                <th width="20%">Associated Company</th>
                <th width="50%">URL</th>
                <th width="10%"><center>Delete</center></th>
                </tr>
                </thead>

                <tbody>
                {sites.length !== 0 ? (
                    sites.map((site, siteIndex) => {
                        let site_id = site.site_id;
                        let company_id = site.company_id;
                        let company_ticker = site.ticker;
                        let company_name = site.company_name;
                        let company_country = site.country;
                        let site_title = site.site_name;
                        let site_url = site.site_url;

                        return(
                            <tr key={`site-item-${siteIndex}`}>
                            <td valign="top">{site_title}</td>
                            <td valign="top">{company_name}</td>
                            <td valign="top" style={{whiteSpace:"nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><a href={site_url} target="_blank" title={site_url} rel="nofollow">{site_url}</a></td>
                            <td valign="middle" align="center"><img src={AjaxLoaderImg} style={site_to_delete === site_id && deleting ? {display:'block'} : {display:'none'}} /> {<button className="delete-btn-portfolio" style={site_to_delete === site_id && deleting ? {display:'none'} : {display:'block'}} onClick={() => deleteSite(site_id)}><i className="fa-solid fa-trash-can" aria-hidden="true"></i></button>}</td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                    <td colSpan={3}><center>No data found.</center></td>
                    </tr>
                )}
                </tbody>
                </table>
                </div>
                </div>

                </div>

                </div>
                </div>
            )
        }
    }
}

export default SiteUpdates;