import { Component } from "react";
import { getQueryStringValue, removeCookie } from "../utils/general.utils";

class UnsubscribeUser extends Component {
    constructor(){
        super();

        this.state = {
            pageLoaded: false,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        }
    }

    async componentDidMount() {
        try {                  
          const main_action_url = "https://insidearbitrage.com/cct-scripts/unsubscribe.php"; 
          const apiKey = this.state.apiKey;

          const user_email = getQueryStringValue("email");
          const token = getQueryStringValue("token");

          const postData = {
            user_email: user_email,
            token: token,
          }
          const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }         
          const response = await fetch(main_action_url, requestOptions);
          const ajaxResponse = await response.json();

          if (ajaxResponse.length > 0) {
            const { errorMsg, successMsg, unsubscribe_status } = ajaxResponse[0];

            if(errorMsg !== ""){
                this.setState({
                    pageLoaded: true,
                    displayError: true,
                    displaySuccess: false,
                    errorMsg: errorMsg,
                    successMsg: ""
                });
            } else {
                if(successMsg !== "" && unsubscribe_status === "unsubscribed"){
                    this.setState({
                        pageLoaded: true,
                        displayError: false,
                        displaySuccess: true,
                        errorMsg: "",
                        successMsg: successMsg
                    });                     
                    
                    removeCookie('cct_username');
                    removeCookie('cct_password');
                    removeCookie('cct_user_email');
                    removeCookie('cct_user_id');
                } else {
                    this.setState({
                        pageLoaded: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: "There is some error while processing your request. Please try again.",
                        successMsg: ""
                    });
                }
            }
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

    render(){
        document.title = 'Conference Call Transcripts';

        const { pageLoaded, displayError, errorMsg, displaySuccess, successMsg } = this.state;

        return(
            <div id="page-content" className="mt-1 mb-5">
            <div className="container">
            
            <div className="row">
            <div className="col-lg-12">
            <h1 className="entry-title">Unsubscribe</h1>
            </div>
            </div>

            <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
            
            <div className="row mt-2">
            <div className="col-lg-12">
            <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{successMsg}</div>

            <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{errorMsg}</div>
            </div>
            </div>

            </div>

            </div>
            </div>
        )
    }
}

export default UnsubscribeUser;